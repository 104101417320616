/* Global Styling */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


/*font-family: 'Inter', sans-serif; font-family: 'Bebas Neue', cursive; */


body{font-family: 'Open Sans', sans-serif; color: #141414; font-size: 16px; overflow-x: hidden; }

ul, li, h1, h2, h3, h4, h5, h6{margin:0;padding:0;}

a, input[type="submit"], button{transition:ease-in-out 0.4s;}

h1, h2, h3, h4, h5, h6{font-family: 'Open Sans', sans-serif;}

p{font-size: 16px;line-height: 28px;}

strong{font-family: 'Open Sans', sans-serif;font-weight: normal;}

.container {max-width: 1120px;}
.container-fluid{max-width: 1220px;}
hr{ border-top: 1px solid #fde6d8; }

/* Global Styling End */
.ready-sec .btn-primary:focus{background-color: #b8132b;border-color:  #fff;box-shadow: 0 0 0 .2rem rgb(184, 19, 43) !important;}
.ready-sec .btn-primary:active{background-color: #fa2040 !important; border-color: #fff !important; }

/* Header Section */

.header-main {
  padding: 10px 0;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  margin-bottom: 10px;
  background: black;
}
 .header-main .navbar-expand-lg .navbar-collapse {
   justify-content: right;
 }

 .main-navigation {display: flex;justify-content: space-evenly; width: 100%;}
 .main-navigation .navbar {
   justify-content: flex-end !important;
   width: 100%;
 }
 body header .main-navigation .navbar ul li a.nav-link{padding: 10px 20px !important;font-family: 'Open Sans', sans-serif;transform: perspective(1px) translateZ(0);position: relative;overflow: hidden;}
 .main-navigation ul li .nav-text-link:hover,
 .main-navigation ul li .nav-text-link:focus {
   color: #4285f4 !important;
 }

 .nav-btn {
   border-radius: 6px;
   color: #fff !important;
   text-transform: uppercase;
   font-weight: bold;
   background: #4285f4;
   border: 1px solid #4285f4; 
 }

 .nav-btn:hover::before {
   left: 51% !important;
   right: 51% !important;
   width: 0px !important;
 }

.nav-btn:hover {
  background: #0069d9; 
  border: 1px solid #fff; 
}

.nav-btn:hover::before,
.nav-btn:focus::before,
.nav-btn:active::before {
  left: 51px !important;
  right: 51px !important;
  width: 0px !important;
}

.main-navigation ul li:last-child .nav-link {
  background: #fff;
  color: #000 !important;
  border: 1px solid black;
}

.main-navigation ul li:last-child .nav-link:hover {
  color: #fff !important;
  background: #0069d9; 
  border: 1px solid #fff; 
}

.cl-black{color: #000 !important   }


 /*.main-navigation ul li:last-child .nav-link {background: #00eeff;color: #0b375b !important;border-radius: 6px; font-family: 'Open Sans', sans-serif;}
 .main-navigation ul li:last-child .nav-link:hover{ background: #efc150; }*/
 .main-navigation ul li .nav-link::before {
   content: "";
   position: absolute;
   z-index: -1;
   left: 51%;
   right: 51%;
   bottom: 0;
   background: #4285f4;
   height: 4px;
   -webkit-transition-property: left, right;
   transition-property: left, right;
   -webkit-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -webkit-transition-timing-function: ease-out;
   transition-timing-function: ease-out;
 }

 @media (min-width: 991px) {
 .main-navigation ul li .nav-link:hover::before, .main-navigation ul li .nav-link:focus::before, .main-navigation ul li .nav-link:active::before {left: 0;right: 0;margin: 0 auto; width: 62%;}
}

 .main-logo { display: inline-block; position: relative; z-index: 9; }
 .main-logo img { width: 100% }
 .sec-space{padding: 60px 0;}

 #footer-logo { width: 150px; }

/* Banner Section */

.banner-sec { padding-top: 20px;}
.banner-sec .container {position: relative;}
 .banner-sec .col-md-6{position: inherit;}
 .banner-sec .main-heading{position: relative;}
 .banner-sec h2 { font-size: 40px; position: relative; z-index: 3;font-family: 'Inter', sans-serif;font-weight: 800;line-height: 60px;margin: 8px 0 12px;}
 .banner-sec p {margin-bottom: 35px;font-style: italic;font-size: 22px;}
 .get-started-btn {margin-right: 20px;}
 .get-started-btn  {min-width: 180px;background: #4285f4; border: 2px solid #fff; padding: 15px 0;border-radius: 10px;color: #fff;text-transform: uppercase;font-weight: 600;}
 .get-started-btn:focus .get-started-btn:hover{background: #233b6f; border: 2px solid #fff; font-family: 'Open Sans', sans-serif; }
 .book-demo-btn { background: #ab1f23 }
 .book-demo-btn:focus,.book-demo-btn:hover{background:rgb(202, 35, 41) !important; border: 2px solid #fff; font-family: 'Open Sans', sans-serif; }
/**/
.banner-sec .col-md-5{position: inherit;}

.banner-sec .video-wrapper { margin-top: 20px; position: relative; padding-bottom: 56.25%; }
.banner-sec iframe {position: absolute; top:0; left: 0; width: 100%; height: 100%; border: 1px solid #ccc;}
.banner-sec .banner-logo {position: absolute;bottom: -14vw;max-width: 45%;z-index: 999;right: 40px;}
.banner-sec-red-headline {color: #e3282f;font-weight: 600;font-family: 'Inter', sans-serif;}

.main-heading h2 { font-size:55px; }


/* Banner Bootom Section */

 .banner-bottom-icon-sec {text-align: center;}

/* Feature Section */

 .features-sec {text-align: center;}
 .features-sec p {text-align: left}

 .features-sec img {height: 70px;}
 .features-sec h3 {font-size: 24px;color: #1d1e20;margin: 30px 0 20px;font-weight: 600;font-family: 'Inter', sans-serif;}
 .features-sec p {color: #1d1e20;font-family: 'Open Sans', sans-serif;}
 .features-sec .features-in {padding: 60px 20px 30px;border-radius: 10px;min-height: 340px;position: relative;}
/*
 .features-sec .features-in:hover{box-shadow: 0px 8px 18px 0px #0001;}
*/
 .frog-icon-sec {text-align: center;background: #c2fbff;padding: 100px 0;}



/* About Section */

.about-sec{position: relative;}
.about-sec-in {
    background-image: url('../../images/marketing/about-sec-bg.jpg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: 35vw;
}
.about-sec span{font-family: 'Inter', sans-serif;color: #fff;font-weight: 600;text-transform: uppercase;}
.about-sec h3 {font-family: 'Inter', sans-serif;font-size: 1.3vw;
    color: #fff; font-weight: bold; line-height:2.3vw;}
.about-sec-inner {padding:18% 15% 12%;position: relative;}
.about-sec p {color: #fff;text-transform: uppercase;font-family: 'Inter', sans-serif;
	font-weight: bold;margin: 0 0 10px;font-size: 1.2vw;}
.about-sec-text{ position: absolute; bottom: 85px; right: 24px; font-family: 'Bebas Neue', cursive; transform: rotate(-90deg);
font-size: 28px; color: #fff; letter-spacing: 2px; }


/* screen section */
.screen-sec{padding: 150px 0px;background: #f5f5f5;overflow: hidden;}
.main-heading h6{font-family: 'Inter', sans-serif; color: #233b6f;text-transform: uppercase;margin-bottom: 10px;font-weight: 800;}
.black-heading{font-family: 'Inter', sans-serif; max-width: 830px;margin: 0 auto 30px;width: auto;color: #1d1e20;font-weight: bold;}
.screen-sec p{max-width: 720px;margin: 0 auto 100px;width: auto;}

.screen-box{margin: auto auto auto 40px;}
.screen-box span{font-family: 'Inter', sans-serif;color: #233b6f;font-weight: bold;padding-left: 35px;position: relative;}
.screen-box span:before{position: absolute;content: "";width: 25px; height: 3px;background: #233b6f;left: 0;top: 8px;}
.screen-box h3{font-family: 'Inter', sans-serif;color: #1d1e20;font-weight: bold;margin-top: 10px;}
.screen-box p{margin: 16px 0;font-size: 15px;}
.screen-box ul{margin-left: 1em;}
.screen-box li{padding-bottom: 0.25em;}


/* Testimonial Section */
.testimonial-sec{padding:100px 0px 80px;overflow-x: hidden;}
.testimonial-sec-slider{padding-top: 50px;}
.testimonial-sec .owl-stage-outer { overflow: visible; }
.testimonial-sec .owl-item-inner{padding: 50px 30px 30px;position: relative; box-shadow: 0px 10px 40px 0px #0001 !important;border-radius: 10px; min-height: 350px; }
.testimonial-sec .owl-item-inner{position: relative; box-shadow: 0px 10px 40px 0px #0001 !important;border-radius: 10px;  }
.testimonial-sec .owl-item-inner::before {content: '';background-image: url('../../images/marketing/quotes-blue.png');position: absolute;left: 0;top: -25px; width: 64px;height: 55px;right: 0;margin: 0 auto;}
.testimonial-sec-slider .owl-item-inner .quote-img{position: absolute;top: -70px;right: 85px;width: 65px;}
.testimonial-speaker {
  position: absolute;
  bottom: 30px;
}
.testimonial-speaker p{color: #1d1e20;font-style: italic;}
.testimonial-speaker h2 {font-family: 'Inter', sans-serif;font-weight: bold;font-size: 16px;color: #1d1e20;margin: 40px 0 10px 130px;}
.testimonial-speaker h6{ margin: 0 0 0 130px; }
.testimonial-sec-slider .owl-carousel .owl-nav button.owl-prev, .testimonial-sec-slider .owl-carousel .owl-nav button.owl-next {padding: 0 !important;border: 1px solid #4285f4 !important;background: transparent !important;color: #4285f4 !important;border-radius: 50%;width: 50px;height: 50px;font-size: 22px;margin: 0 5px;}
.testimonial-sec-slider .owl-nav {text-align: center;margin: 50px 0 0;}

.testimonial-inner img { 
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #666;
  margin-top: 20px;
  float: left;
}

/* Dashboard Section */
.dashboard-sec{padding: 130px 0px 80px;background: url(../../images/marketing/dashboard.jpg);background-size: cover;color: #fff;overflow-y: visible;}
.dashboard-sec-inner h6{font-family: 'Inter', sans-serif;font-weight: bold;margin: 50px 0 12px;}
.dashboard-sec-inner h3{font-family: 'Inter', sans-serif;font-weight: bold;margin-bottom: 16px;}
.dashboard-sec-inner p{margin-bottom: 10px;font-size: 15px;line-height: 22px;}
.dashboard-sec .laptop-img {box-shadow: 0 10px 10px -10px #000000;}



/* Accordion Section */
.accordion-sec { padding: 130px 0px 50px;font-family: 'Inter', sans-serif; }
.accordion .card{border: 0px;box-shadow: 0px 5px 9px 0px #0003;margin-bottom: 18px !important;}
.accordion .card-header{border: 0px;background: #fff;}
.accordion .card-header .card-title{border: 0px;color: #1d1e20;}
.accordion .card-header .card-title:hover{text-decoration: none !important;}
.accordion-sec .accordion .card .card-body p{line-height: 24px;}
.accordion-sec .accordion .card-header::after {font-family: 'Font Awesome 5 Pro';content: "\f068";color: #4285f4;position: absolute; right: 10px; top: 13px;}
.accordion-sec .accordion .card-header.collapsed:after { content: "\f067"; }
.accordion-sec .card-header { cursor: pointer; }

/* Ready Section */

.ready-sec{ margin-bottom: 120px; }
.ready-sec-inner{padding: 100px 0px;border-radius: 10px;background: black;background-size: cover;}
.ready-sec-inner h6{font-family: 'Inter', sans-serif; color: #fff;font-weight: bold;text-transform: uppercase;margin-bottom: 20px;}
.ready-sec-inner h2 {font-family: 'Inter', sans-serif;color: #fff;font-weight: 800;max-width: 750px;margin: 0 auto 30px;width: auto;font-size: 40px;}
.ready-sec-inner a{min-width: 210px;background: #4285f4;padding: 15px 0;border-radius: 10px;color: #fff;text-transform: uppercase;font-weight: 900;}
.ready-sec-inner a:hover{background: #233b6f;}

/* Footer */

.footer-main{background: #f5f5f5;padding: 85px 0 10px;}
.footer-link h2{color: #1d1e20;font-family: 'Inter', sans-serif;position:relative;font-weight:bold;font-size: 18px;margin-bottom: 25px;}
.footer-main p{margin-top: 35px;color: #79797c;}
.footer-link ul{list-style: none;}
.footer-link ul li{padding: 0px 0px 10px;}
.footer-link li a{color: #79797c;}
.footer-link ul li a i{padding-right: 10px; }
.footer-link li a:hover{text-decoration: none;color: #b8132b}
.footer-frm input{padding: 6px 14px 12px;background: #f5f5f5;border: 1px solid #79797c;border-radius: 10px;width: 100%;}
.footer-frm button{background: #4285f4;color: #fff;width: 100%;text-align: center;font-weight: bold;border-radius: 10px;margin-top: 20px;}
.footer-frm button:hover {background: #233b6f;color: #fff;width: 100%;text-align: center;font-weight: bold;border-radius: 10px;margin-top: 20px;}
.footer-link h2::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 1px;
    background: #bbbbbc;
    bottom: -10px;
}
.footer-link h2::after {
    content: '';
    background: #b8132b;
    width: 20px;
    height: 2px;
    position: absolute;
    bottom: -10px;
    left: 0;
}




.inner-main-heading h6{font-family: 'Inter', sans-serif;color: #233b6f;text-transform: uppercase;margin-bottom: 10px;font-weight: 800;}
.inner-main-heading h2{font-family: 'Inter', sans-serif;max-width: 830px;margin: 0 auto 30px;width: auto;color: #1d1e20;font-weight: bold;font-size: 42px;}


/* signup page */
.signup-sec{padding: 85px 0px;}

.signup-sec form{font-family: 'Inter', sans-serif;color: #1d1e20;}
.signup-sec form input{background: #f5f5f5;border: 0;padding: 25px 20px;}
.signup-sec form .form-text{color: #1d1e20;font-style: italic;}

.signup-sec .btn.btn-primary {background: #4285f4; border: 2px solid #fff; padding: 15px 0;border-radius: 10px;color: #fff;text-transform: uppercase;font-weight: 600; width: 100%}
.signup-sec .btn.btn-primary:focus, .signup-sec .btn.btn-primary:hover{background: #233b6f; border: 2px solid #fff;}

.signup-sec form .form-control:focus{box-shadow: 0 0 0 .2rem rgba(184, 19, 43, 0.37);}
.signup-sec form .form-check{padding-left: 40px;font-style: italic;color: #1d1e20;}
.signup-sec form .forget-btn a{color: #b8132b;font-style: italic;}
.signup-sec form .form-text a{color: #b8132b;font-style: italic;}


/* contact us page */
.contact-screen-sec {padding: 40px 0px;position: relative;}
.contact-screen-sec .contact-screen-box span {font-family: 'Inter', sans-serif;color: #233b6f;font-weight: bold;}
.contact-screen-sec .contact-screen-box h3 {font-family: 'Inter', sans-serif;color: #1d1e20;font-weight: bold;margin: 10px 0 25px;font-size: 60px;}
.contact-screen-sec .contact-screen-img{}
.contact-screen-sec .contact-screen-box p{max-width: 80%;margin-bottom: 35px;font-style: italic;font-size: 18px;}
.contact-screen-sec .contact-screen-img img{border-radius: 30px 0 0 30px;width: 41%;position: absolute;right: 0;top: 5%;height: 90%;object-fit: cover;}

.contact-sec{margin-top: 30px;padding: 60px 0px;background: #f5f5f5;}
.contact-sec form input {background: #f5f5f5;border: 0;padding: 25px 20px;}
.contact-sec form textarea {background: #f5f5f5;border: 0;padding: 25px 20px;}
.contact-sec-inner{background: #fff;padding: 125px 100px 145px 20px;position: relative;overflow-x: visible;}
.contact-sec-inner::before {
    position: absolute;
    content: "";
    top: 0;
    left: -50%;
    background: #fff;
    width: 50%;
    height: 100%;
    border-radius: 10px 0 0 10px;
}


.contact-sec-inner form .btn.btn-primary {background: #4285f4;border: #4285f4;width: 40%;padding: 12px;text-transform: uppercase;border-radius: 10px;font-weight: 600;}
.contact-sec-inner form .btn.btn-primary:hover {background: #233b6f;}
.contact-sec-inner h4{font-family: 'Inter', sans-serif;font-size: 32px;font-weight: bold;margin-bottom: 15px;}
.contact-sec-inner p{color: #1d1e20;margin-bottom: 25px;}

.contact-colr-sec{background-color: #4285f4;padding: 110px 50px 50px;color: #fff;margin-top: 75px;border-radius: 10px;}
.contact-colr-sec h4{font-family: 'Inter', sans-serif;font-weight: bold;margin-top: 20px;}
.contact-colr-sec p{font-size: 15px;margin-bottom: 45px;}
.contact-colr-sec .contact-social h4{margin-bottom: 10px;}
.contact-colr-sec .contact-social h5{margin-bottom: 30px;}

.ready-sec.contact-ready{padding-top: 90px !important;}


/* Prices page */
.price-sec{padding: 20px 0px;background-color: #f5f5f5;}
.price-sec-inner{position: relative;background-color: #fff;padding: 60px 30px;border-radius: 10px;text-align: center;border-radius: 10px;height: 800px;margin-bottom: 55px;}
.price-sec-inner h5{font-size: 18px;font-family: 'Inter', sans-serif;font-weight: bold;color: #233b6f;margin-bottom: 5px;}
.price-sec-inner h6{margin: 10px 0px;font-weight: bold;color: #1d1e20;font-size: 14px;}
.price-sec-inner h3{font-size: 50px;font-family: 'Inter', sans-serif;font-weight: bold;color: #4285f4;margin: -10px 0px;}
.price-sec-inner h3 span{font-size: 20px;vertical-align: middle;}
.price-sec-inner p{color: #1d1e20; font-size:90%; margin: 0px;}
.price-sec-inner a.btn {min-width: 210px;background-color: transparent;border: 2px solid #4285f4;padding: 15px 0;border-radius: 10px;color: #4285f4;text-transform: uppercase;font-weight: 900;margin-top: 20px;}
.price-sec-inner a.btn:hover{background: #4285f4;color: #fff;border-color: #4285f4;}
.price-sec-inner button {min-width: 210px;background-color: transparent;border: 2px solid #4285f4;padding: 15px 0;border-radius: 10px;color: #4285f4;text-transform: uppercase;font-weight: 900;margin-top: 20px;}
.price-sec-inner button:hover{background: #4285f4;color: #fff;border-color: #4285f4;}
.price-sec-inner hr{border-color: #f5f5f5;margin: 25px 0px;}
.price-sec-inner-top {min-height: 340px; display: flex; flex-direction: column; justify-content: space-evenly; }
.price-sec-inner .price-link{text-align: left;}
.price-sec-inner .price-link h4{font-size: 14px;font-weight: bold;margin-bottom: 16px;text-transform: uppercase;}
.price-sec-inner .price-link ul{list-style: none;}
.price-sec-inner .price-link ul li{padding: 6px 0px;}
.price-sec-inner .price-link ul li i{color: #79797c;padding-right: 6px;}
.price-sec-inner .price-link ul li {color: #1d1e20;font-size: 12px;}
.price-sec-inner .price-request-btn{background-color: #4285f4 !important;color: #fff !important;}
.price-sec-inner .price-request-btn:hover{background-color: #233b6f !important; }

.price-sec-inner:after{position: absolute;content: "";width: 100%;height: 100%;box-shadow: 0 0px 10px black;left: 0;top: 0;border-radius: 10px;pointer-events: none;}

.request-quote-inner{background: #fff;position: relative;overflow-x: visible; padding: 20px;}


.request-quote-inner form .btn.btn-primary {background: #4285f4;border: #4285f4;width: 40%;padding: 12px;text-transform: uppercase;border-radius: 10px;font-weight: 600;}
.request-quote-inner form .btn.btn-primary:hover {background: #233b6f;}
.request-quote-inner h4{font-family: 'Inter', sans-serif;font-size: 32px;font-weight: bold;margin-bottom: 15px;}
.request-quote-inner p{color: #1d1e20;margin-bottom: 25px;}


/* About Us Page */
.about-slider-sec{padding: 100px 0px;}
.about-slider-sec .owl-carousel .owl-item-inner { padding: 20px; }
.about-slider-sec .owl-carousel .item .owl-item-inner p {color: #1d1e20;font-size: 16px;font-style: italic;line-height: 30px;}
.about-slider-sec .owl-carousel .item .owl-item-inner h2 {color: #1d1e20;font-size: 16px;font-family: 'Inter', sans-serif;font-weight: bold;margin-left: 70%;margin-bottom: 5px;}
.about-slider-sec .owl-carousel .item .owl-item-inner h6{color: #1d1e20;font-size: 14px;font-family: 'Inter', sans-serif; margin-left: 70%;}

.about-slider-sec .owl-carousel .owl-nav button.owl-prev, .about-slider-sec .owl-carousel .owl-nav button.owl-next {
    padding: 0 !important; border: 1px solid #B8132B !important;background: transparent !important;color: #B8132B !important;position: absolute;top: 50%;transform: translateY(-50%);border-radius:50%;width: 50px;height: 50px; font-size: 20px;}
.about-slider-sec .owl-carousel .owl-prev { left: -4rem; }
.about-slider-sec .owl-carousel .owl-next { right: -4rem; }

.bio-sec{padding: 120px 0px;background-color: #f5f5f5;}
.bio-sec-main{box-shadow: 0 0 10px 0px #a6a6a6;border-radius: 10px;position: relative;margin-bottom: 30px;height: 95%;background: #fff;}
.bio-sec-main img{border-radius: 10px 10px 0 0; width: 100%;height: 374px;
object-fit: contain; }
.bio-sec .bio-sec-inner{background: #fff;padding: 30px 20px;border-radius: 0 0 10px 10px;}
.bio-sec .bio-sec-inner h4{color: #1d1e20;font-family: 'Inter', sans-serif;font-weight: bold;font-size: 18px;text-align: center;}
.bio-sec .bio-sec-inner p{text-align: center;color: #1d1e20;}
.bio-sec .bio-sec-inner h6{text-align: right;}
.bio-sec .bio-sec-inner h6 a{color: #b8132b;font-weight: bold;text-decoration: underline;}
.bio-sec .bio-sec-inner{}

.bio-sec-detail{padding: 30px;overflow-y: scroll;position: absolute;content: "";right: -26vw;top: 0;width: 100%;height: 100%;background: #fff;z-index: 99;}
.bio-sec-detail h4{color: #b8132b;font-family: 'Inter', sans-serif;font-weight: bold;margin-bottom: 10px;}
.bio-sec-detail h5{color: #b8132b;font-family: 'Inter', sans-serif;font-weight: bold;font-size: 16px;}
.bio-sec-detail p{font-size: 12px;line-height: 1.5;margin-top: 15px;}
.bio-sec-detail h6{color: #b8132b;font-family: 'Inter', sans-serif;font-weight: bold;}

.view-less-team { display: none; }
.bio-sec .bio-sec-inner a.btn{color: #4285f4;font-weight: bold;text-decoration: underline; padding: 0;}
.teambio-content {
    display: none;position: absolute;top: 0;background: #fff;left: 98%;width: 400px;z-index: 9 !important;
    height: 100%;overflow-y: auto;padding: 20px; box-shadow:6px 4px 10px 0px #a6a6a6; border-radius: 0 15px 15px 0;
}
.teambio-content-right {left: inherit;right: 100%;border-radius: 15px 0 0 15px;}
.bio-sec-main h3 {font-size: 24px;color: #233b6f;font-weight: bold;margin: 0 0 15px;}
.bio-sec-main h5 {font-size: 16px;font-weight: bold;color: #4285f4;margin: 0 0 8px;}
.bio-sec-main .teambio-content p {line-height: 24px;}

.section-blog {
  background: #f5f5f5;
  padding: 100px 0px 60px 0px;
}

#blog {
  width: 100%;
}
/* blog carousel */
.blog-inner { 
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.blog-item {
  display: flex;
  flex-direction: column;
  width: calc(100vw / 4 - 30px);
  cursor: pointer;
}


.blog-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
  border: 1px solid #777;
}

.blog-item h5 {
  width: 100%;
  font-weight: 500;
  font-size: 1.15em;
  font-family: "Inter";
}

#blog-carousel { 
  margin: auto;
  max-width: 1000px;
  padding: 50px 0px 0px px;
}

.carousel-item {
  cursor: pointer;
  height: 500px;
  max-width: 1000px;
  background-size: cover;
  background-position: center;
  position: relative;
}


.blog-carousel-caption h5 {
  color: white;
  position: relative;
  z-index: 1;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 30px;
}

.blog-carousel-caption div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.blog-carousel-caption div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.50)
}


.blog-carousel-nav { 
  text-align: center;
  padding: 20px;
}

.blog-carousel-nav button {
  padding: 0 !important;
  border: 1px solid #4285f4 !important;
  background: transparent !important;
  color: #4285f4 !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 22px;
  margin: 0 5px;
}


/* Blog Page */

.blog-heading{color: #b8132b;font-family: 'Inter', sans-serif;font-weight: bold;margin-bottom: 40px;}
.latest-post-sec{padding: 100px 0px;}
.latest-post-sec .latest-post-inner img{border-radius: 10px 10px 0 0;}
.latest-post-sec .latest-post-inner .post-inner{padding: 25px 30px 30px;box-shadow: 0 0 10px 0px #a6a6a6;border-radius: 10px;border-radius: 0 0 10px 10px;}
.latest-post-sec .latest-post-inner .post-inner p{}
.latest-post-sec .latest-post-inner .post-inner p span{color: #b8132b;}
.latest-post-sec .latest-post-inner .post-inner h4{color: #1d1e20;font-size: 16px;margin-top: 15px;font-family: 'Inter', sans-serif;font-weight: bold;line-height: 22px;}
.latest-post-sec .latest-post-inner .post-inner h5{color: #1d1e20;font-size: 14px;margin-top: 15px;line-height: 20px;}
.latest-post-sec .latest-post-inner .post-inner h6{text-align: right;margin: 20px 14px;}
.latest-post-sec .latest-post-inner .post-inner h6 a{color: #b8132b;font-weight: bold;text-decoration: underline;}
.blog-images{height: 80% !important;}

.latest-post-sec .post-right-main{box-shadow: 0 3px 7px 0px #a6a6a6;border-radius: 10px;margin-bottom: 25px; overflow: hidden;}
.latest-post-sec .post-right-main img { min-height: 95px; }
.latest-post-sec .post-right-side { padding: 8px 10px; }
.latest-post-sec .post-right-side p { margin-bottom: 0px; font-size: 13px; }
.latest-post-sec .post-right-side p span{ color: #b8132b; }
.latest-post-sec .post-right-side h4{ line-height: 10px; }
.latest-post-sec .post-right-side h4 a{ color: #1d1e20;font-size: 14px;font-family: 'Inter', sans-serif;font-weight: bold; }
.latest-post-sec .post-right-side h4 a:hover{color: #1d1e20;text-decoration: none;}
.latest-post-sec .post-right-main .col-md-8{ padding-left: 0; }
.latest-post-sec h2{ font-family: 'Inter', sans-serif; font-weight: bold; }

.latest-post-sec .latest-post-inner{margin-bottom: 40px;}
.load-post-btn {min-width: 210px;background: #b8132b;border: 2px solid #fff;padding: 15px 40px;border-radius: 10px;
    color: #fff;text-transform: uppercase;font-weight: 900;}
.load-post-btn:hover{color: #fff;text-decoration: none;}


.featured-post-sec{padding: 100px 0px;background: #f5f5f5;}
.featured-post-sec .owl-stage-outer { padding-bottom: 20px; padding-top: 10px; }
.featured-post-sec .owl-carousel .owl-item-inner{box-shadow: 0 0 0 !important;}
.featured-post-sec .owl-carousel .owl-wrapper-outer{width: 100%;}
.featured-post-sec .owl-carousel .featured-post-box { box-shadow: 0 0 10px 0px #a6a6a6;border-radius: 10px;background: #fff;overflow: hidden;}
.featured-post-sec .owl-carousel .item .post-item-inner p{color: #1d1e20;font-size: 16px;}
.featured-post-sec .owl-carousel .item .post-item-inner h2{color: #1d1e20;text-align: right;font-size: 15px;font-family: 'Inter', sans-serif;font-weight: bold;}
.featured-post-sec .owl-carousel .item .post-item-inner h6{color: #1d1e20;text-align: right;font-size: 16px;font-weight: bold;text-transform: uppercase;}
.featured-post-sec .owl-carousel .owl-nav button.owl-prev, .featured-post-sec .owl-carousel .owl-nav button.owl-next {
    padding: 0 !important; border: 1px solid #B8132B !important;background: transparent !important;color: #B8132B !important;position: absolute;top: 50%;transform: translateY(-50%);border-radius:50%;width: 50px;height: 50px;}
.featured-post-sec .owl-carousel .owl-prev {left: -4rem;}
.featured-post-sec .owl-carousel .owl-next {right: -4rem;}
.featured-post-sec .owl-carousel .owl-prev i,.featured-post-sec .owl-carousel .owl-next i{font-size: 20px;}
.featured-post-sec .owl-carousel .post-item-inner{padding: 16px;}

.featured-post-sec .owl-carousel .post-item-inner p{}
.featured-post-sec .owl-carousel .post-item-inner p span{color: #b8132b;}
.featured-post-sec .owl-carousel .post-item-inner h4{color: #1d1e20;font-size: 16px;margin-top: 15px;font-family: 'Inter', sans-serif;font-weight: bold;line-height: 22px;}
.featured-post-sec .owl-carousel .post-item-inner h5{color: #1d1e20;font-size: 16px;margin-top: 15px;line-height: 24px;}
.featured-post-sec .owl-carousel .post-item-inner h6{text-align: right;margin: 20px 14px;}
.featured-post-sec .owl-carousel .post-item-inner h6 a{color: #b8132b;font-weight: bold;text-decoration: underline;}
.featured-post-sec .btn { padding: 0; color: #b8132b; font-weight: 700; text-transform: uppercase; text-decoration: underline; margin: 20px 0 0; }

.inner-main-heading h2 br {
    display: none;
}








/* Animations */

@keyframes zooming {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.zoom-content {
  animation-name: zooming;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}




/* Single Article Page */

.single-article-outer {
    padding: 60px 0 80px;
}

.single-article-outer .container {
    max-width: 990px;
}
.single-article-inner {
    margin: 0 40px;
}
.single-article-img {
    margin: 0 0 60px;
}
.article-meta-text {
    margin: 0 0 20px;
}
.article-meta-text li {
    display: inline-block;
    margin: 0 25px 0 0;
    color: #79797c;
    position: relative;
}
.article-meta-text li::before {
    content: '|';
    position: absolute;
    right: -20px;
    top: -1px;
}
.article-meta-text li a {
    color: #b8132b;
}
.article-meta-text li:last-child::before {
    display: none;
}
.single-article-outer h2 {
    font-size: 36px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 50px;
    margin: 0 0 40px;
}
.single-article-outer h3 {
    font-size: 30px;
    font-weight: bold;
    margin: 40px 0 20px;
    font-family: 'Inter', sans-serif;
}
.single-article-outer p {
    line-height: 24px;
    margin: 0 0 40px;
}
.article-nav .btn {
    color: #79797c;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    outline: none !important;
    box-shadow: none;
}
.article-nav .btn:hover{ color: #b8132b; }
.single-article-inner .img-fluid {
    margin: 20px 0;
}
.featured-post-sec h2 {
    font-size: 32px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
}

.screen-sec .black-heading {
	font-size: 32px;
}

.screen-sec .col-md-5 {
	display: flex;
	justify-content: center;
	align-items: center;
}
.about-container p {
	font-size: 23px !important;
	line-height: 30px;
	max-width: 90% !important;
}
.about-screen {
	padding: 65px 0px;
	position: relative;
}


/* Single Article Page End */


.login-company-container img {
  max-width: 100px;
}

.login-company-container .row {
  border: 1px solid #f2d4d8;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
  align-items: center;
  background: #ffffff;
}

.login-company-container i {
  width: 100px;
  height: 100px;
  font-size: 100px;
  color: #fff;
}
.login-company-container a {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  color: #b8132b;
}

.login-sub-text {
  max-width: 500px;
  margin: 0px auto;
}

.login-sent-link {
  margin-bottom: 30px;
  border: 1px solid #b8132b;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 1px 1px 1px #ccc;
}


.contact-section ul {
  margin: 2em;
}


.floater {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 20px;
  border-radius: 20px;
  background: white;
  z-index: 50;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #ccc;
}

.floater img {
  height: 40px;
  margin-right: 10px;
}

#book-demo-form {
  max-width: 500px;
  display: none;
}

#book-demo-form .text-small {
  font-size: 60%;
}

.notification { 
  position: absolute;
  top: 100px;
  width: 100%;
  z-index: 100;
}

.notification span {
  background: #ffd61c;
  padding: 10px;
}
.floater form input[type=email] {background: #f5f5f5;border: 0;padding: 25px 20px;}
.floater form textarea {background: #f5f5f5;border: 0;padding: 25px 20px;}
