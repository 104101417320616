
.marketing-header-logo {
	margin-left: calc(max((100vw - 1120px) / 2, 0px));
	padding-left: 15px;
}

@media ( min-width: 1400px ) and ( max-width: 2500px ){
	.banner-sec .banner-sec-text{width: 100%;}
	.about-sec h3{font-size: 1.2rem; line-height: 1.5rem;}
	.about-sec p{font-size: 1rem;}
}


@media ( min-width: 992px ) and ( max-width: 1301px ){
.container { max-width: 98%; }
.banner-sec .banner-logo { bottom: -5vw; bottom: -8vw; }
.banner-sec h2 {font-size: 4vw;line-height: 5vw;}
.banner-sec ul{ margin: 30px 0 0; }
.banner-sec .banner-moon{ bottom: 50px; }
.features-sec .features-in{ padding: 40px 20px 20px; }
.features-sec h3 { font-size: 2.1vw; }
.features-sec{ padding: 60px 0; }
.road-map-box {	max-width: 43%;	width: auto; text-align: left; top: 0; left: 2%; }
.road-map-box.road-map-box2 { right: 2%; top: 14%; }
.road-map-box.road-map-box3 { top: 27%;	left: 2%; }
.road-map-box.road-map-box4 { top: 41%; right: 2%; }
.road-map-box.road-map-box5 { left: 2%;	top: 54%; }
.road-map-box.road-map-box6 { right: 2%; top: 68%; }
.road-map-box.road-map-box7 { top: 82%; left: 2%; }
.road-map-box.road-map-box8 { top: 95%; right: 2%; }
.road-map-box h3 { font-size: 26px; margin: 0 0 12px; }
.road-map-box .road-map-box-in { padding: 40px 40px 30px 40px; }
.faq-sec .card button { font-size: 1.8vw;}
section.manual-burns-sec {	padding-top: 0; }
.how-to-buy{ height: 55vw; }
.about-slider-sec .owl-carousel .owl-nav button.owl-prev, .about-slider-sec .owl-carousel .owl-nav button.owl-next {
	border: none !important; position: inherit; transform: none; display: inline-block; }
.about-slider-sec .owl-nav {text-align: center; }

body header .main-navigation .navbar-light ul li a.nav-link{padding:10px 1.3vw !important;}
.banner-sec-text {width: 90%;}

}

@media ( min-width: 992px ) and ( max-width: 1205px ){
	#responsive-margin-item { margin-right: 10vw; }
	.inner-header #responsive-margin-item { margin-right: 0px; }
}

@media ( min-width: 1205px ) {
	.inner-header #responsive-margin-item { margin-right: 0px; }
}

@media ( min-width: 768px ) and ( max-width: 991px ){
	.main-navigation .navbar { width: 100%; text-align: right; justify-content: flex-end; }
	.main-navigation .navbar-toggler { padding: 0; color: #fff; font-size: 30px; border: none; }
	.main-navigation .navbar-collapse {	position: absolute;	width: 420px;right: 0;top: 52px;	z-index: 999;background: #fff;
	text-align: center;	padding: 20px;	border-radius: 8px;	box-shadow: 1px 1px 4px -1px #000;}
	.nav-btn-col{color: #000 !important;}

	.banner-sec p { max-width: 100%; font-size: 14px; line-height: 22px; }
	.about-slider-sec .owl-carousel .owl-nav button.owl-prev, .about-slider-sec .owl-carousel .owl-nav button.owl-next {
	border: none !important; position: inherit; transform: none; display: inline-block; }
	.about-slider-sec .owl-nav {text-align: center; }

	.teambio-content {
		max-width: 100%;
	}
	.main-navigation .nav-btn {
		border: 1px solid #e3282f;
		color: #e3282f !important;
		width: 50%;
		margin: 10px auto !important;
		display: block;
	}
	.main-navigation .nav-link.nav-btn:hover{ background: #e3282f; color: #fff !important; }

	.banner-sec-text {width: 95%;}
	.main-heading h2 {font-size: 4vw;line-height: 5vw;}
	.banner-sec h2 {font-size: 4vw;line-height: 5vw;}
	.banner-sec::after{top: -3vw;height: 66vw;}
} /* min-width: 768px - max-width: 991px */


.nav-link { color: #fff !important }

@media (min-width: 991px) {
	.main-navigation ul li:last-child .nav-link{ margin-left: 20px; }
	.nav-item {
		margin-left: 1em;
	}
}

.about-sec-text { display: none; }

/* mobile versions below */
@media (max-width: 767px){
	.main-navigation .navbar { width: 100%; text-align: right; justify-content: flex-end; }
	.main-navigation .navbar-toggler { padding: 0; color: #fff; font-size: 30px; border: none; }
	.main-navigation .navbar-collapse {	position: absolute;	width: 100%;right: 0;top: 52px;	z-index: 999;background: #fff;	text-align: center;	padding: 20px;	border-radius: 8px;	box-shadow: 1px 1px 4px -1px #000;}
	.container { max-width: 96%; }
	.banner-sec::after {width: 54%;height: 55vw;border-radius: 0;right: 0;top:0px;}
	.banner-sec {padding-top: 0px;}
	.banner-sec h2 {font-size: 7vw;line-height: 6vw;}
	.banner-sec p{font-size: 11px;margin-bottom: 20px;line-height: 25px;}

	.get-started-btn {padding: 10px 25px;font-size: 16px;margin-right:0;}

	.banner-sec-button {
		text-align: center;
		margin-bottom: 0;
	}

	.banner-sec-button a,button {
		margin-bottom: 10px;
	}
	/* Mobile about section */

	.about-sec .pr-0, .about-sec .pl-0 { padding: 0 15px !important; }
	.features-sec p {font-size: 14px;}
	.features-sec .features-in {padding: 30px 20px 20px;}
	.features-sec .features-in {min-height: 280px;}

	/* about sec */
	.about-sec-in {	background: none;border-radius: 80px 80px 0 0;overflow: hidden;margin: 0 10px; margin-bottom: 30px;}
	.about-sec-in::before {content: '';width: 86%;position: absolute;height: 98%;border: 14px solid #fff;left: 7%;top: 15px;z-index: 1;border-radius: 70px 70px 0 0;border-bottom: none;}
	.about-sec h3{font-size: 5vw;line-height: 7vw;}
	.about-sec span{font-size: 10px;}
	.about-sec-inner { background: #4285f4; padding: 20% 18% 15%; }
	.about-sec-inner.about-sec-inner-bottom {background: #233b6f; }
	.about-sec-text {display: block; bottom: 65px;right: -52px;font-size: 20px;background: #000;z-index: 2;padding: 5px 20px; }

	.screen-sec{padding: 100px 0px 0px;}
	.main-heading h6{font-size: 10px;}
	.black-heading{font-size: 20px !important;}
	.screen-sec p{font-size: 12px;line-height: 20px;}
	.screen-box{margin: auto 20px;}
	.screen-sec img{margin-bottom: 30px;}


	.testimonial-sec{padding: 100px 0px;}
	.testimonial-sec .owl-carousel .owl-item .testi-img{width: 50% !important;}
	.testimonial-sec .owl-carousel .owl-stage{padding-left: 20px!important;padding-right: 20px!important;}
	.testimonial-sec .owl-carousel .owl-item-inner{padding: 55px 25px;}

	.dashboard-sec{padding: 50px 0px 0px;}

	.accordion-sec{padding: 80px 0px 80px}
	.accordion .card-header .card-title{font-size: 14px;}
	.accordion .card .card-body p{font-size: 13px;}
	.ready-sec-inner{padding: 50px 20px;}
	.ready-sec-inner h2{font-size: 23px;margin: 0 auto 25px;}
	.ready-sec-inner a{min-width: 180px;padding: 9px 0;}

	.header-main .navbar-expand-lg .navbar-collapse{text-align: center !important;justify-content: center;}
	.nav-btn-col{color: #000 !important;}
	.nav-item{text-align: center;}
  .nav-link { color: #000 !important }
	.nav-btn {
		color: #fff !important;
		width: 50%;
		margin: 10px auto;
	}
	.main-navigation ul li:last-child .nav-link{margin: 10px auto;color: #000 !important; }
	.main-navigation ul li .nav-link:hover::before, .main-navigation ul li .nav-link:focus::before, .main-navigation ul li .nav-link:active::before{left: 58px;right: 58px;}

	.contact-screen-sec{padding:10px 0px;}
	.contact-screen-sec .contact-screen-img{text-align: right; }
	.contact-screen-sec .contact-screen-img img{width: 84%;position: relative;}
	.contact-screen-box{padding:15px 0px 0px;}

	.supporting-screen-sec .supporting-screen-img img{display:none;}
	.supporting-screen-sec{padding:10px 0px;}
	.supporting-screen-box{padding:15px 0px 0px;}
	.supporting-screen-sec .supporting-screen-box h3 {
		font-size: 40px;
		text-align: center;
	}

	#news-section {
		margin-top: 0;
	}

	.contact-sec{margin-top: 0;}
	.contact-sec-inner::before{display: none;}
	.contact-sec-inner {padding: 8% 8% 160px;border-radius: 10px;box-shadow: 0 0 10px 0px #a6a6a6; }

	.contact-screen-sec .contact-screen-box h3{font-size: 8vw;}
	.price-sec{padding: 50px 0px; background: #fff;}
	.footer-link h2{ margin-top: 10px; }
	.footer-frm { width: 320px;	max-width: 100%; }
	.footer-main .col-sm-6 { width: 50%; }

	.about-slider-sec .owl-carousel .owl-nav button.owl-prev, .about-slider-sec .owl-carousel .owl-nav button.owl-next {
	border: none !important; position: inherit; transform: none; display: inline-block; }
	.about-slider-sec .owl-nav {text-align: center; }

	.bio-sec-main {max-width: 480px;margin: 0 auto 30px; }
	.teambio-content {top: 100%;left: 0;width: 100%;overflow-y: auto;box-shadow: 0 9px 10px 0px #a6a6a6;margin-top: -10px;border-radius: 0 0 15px 15px;}
	.about-slider-sec { padding: 20px 0px; }
	.latest-post-sec { padding: 30px 0px; }
	.testimonial-sec-slider .owl-item-inner h6 {margin: 7px auto auto 130px;font-size: 10px;}
	.bio-sec-main img{height: auto !important}

	.latest-post-sec .post-right-main .col-md-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.latest-post-sec .post-right-main .col-md-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.contact-colr-sec {
		padding: 100px 50px 80px;
		color: #fff;
		margin: -110px 30px 0 30px;
		border-radius: 10px;
	}
	.contact-colr-sec .contact-social h4{ font-size: 18px; }
	.contact-colr-sec .contact-social h5{ font-size: 16px; }
	.inner-main-heading h2{ font-size: 7vw; }
	.inner-main-heading h2 br {	display: inherit; }
	.single-article-outer h2{ font-size: 5vw; line-height: 1.5; }
	.single-article-inner {	margin: 0 0; }
	.single-article-outer h3 { font-size: 24px; margin: 20px 0 10px; }
	.article-nav .col-sm-6 { width: 50%; }
	.testimonial-sec .owl-item-inner{min-height: 405px; }
	.testimonial-sec-slider .owl-nav {margin: 0px 0 0;text-align: unset; }
	.testimonial-sec-slider .owl-carousel.owl-drag .owl-item{margin-right: 25px !important;}
	.testimonial-sec-slider .owl-carousel .owl-nav button.owl-next{float: right!important;}
	.testimonial-sec-slider .owl-carousel .owl-nav button.owl-prev, .testimonial-sec-slider .owl-carousel .owl-nav button.owl-next{border: 0px !important;}
	.dashboard-sec .laptop-img{margin-top: 30px;}

	.pricing-img{display:none;}
	.banner-sec-text { margin-top: 20px;}
	.banner-sec p {
		font-size: 4vw;
		margin-bottom: 7vw;
		max-width: inherit;
	}
	.about-sec p{font-size: 4vw;}

}



#header-image-mobile {
	display: none;
}

#header-image {
	display: inline-block;
	width: 100px;
}


@media ( max-width: 480px ){
	.about-sec { }
	.about-sec-in{ background: none; }
	.about-img1{border-radius: 80px 80px 0 0;}
	.about-img2{border-radius: 0 0 0 0;}
	.features-sec p {font-size: 16px;}
	.features-sec .features-in {padding: 30px 20px 20px;}
	.features-sec .features-in {min-height: 280px;}
	.about-sec-text {  right: -57px; }
	.about-sec-in::before {width: 82%;left: 9%;top: 10px;}



	.screen-sec{padding: 100px 0px 0px;}
	.main-heading h6{font-size: 10px;}
	.black-heading{font-size: 20px;}
	.screen-sec p{font-size: 12px;line-height: 20px;}
	.screen-box{margin: auto 20px;}
	.screen-sec img{margin-bottom: 30px;}


	.testimonial-sec{padding: 50px 0px 0px;}
	.testimonial-sec .owl-carousel .owl-item .testi-img{width: 50% !important;}
	.testimonial-sec .owl-carousel .owl-stage{padding-left: 20px!important;padding-right: 20px!important;}
	.testi-img-div{float: left;width: 30%;padding: 0;}
	.testi-text-div{float: left;width: 70%;padding: 0 0 0 10px;}

	.testimonial-sec .owl-carousel .owl-item .testi-img{width: 100% !important;}
	.testimonial-sec-slider .owl-item-inner p{font-size: 14px;line-height: 24px;margin-top: 20px;}


	.dashboard-sec{padding: 50px 0px 0px;}

	.accordion-sec{padding: 80px 0px 80px}
	.accordion .card-header .card-title{font-size: 14px;}
	.accordion .card .card-body p{font-size: 13px;}
	.ready-sec-inner h2{font-size: 20px;margin: 0 auto 25px;padding: 0 0;}
	.ready-sec-inner a{min-width: 180px;padding: 9px 0;}

	.footer-link h2{margin-bottom: 5px;}
	.footer-link{    margin-bottom: 30px;}

	.footer-link h2::before {
		display: none;
	 }

	.footer-link h2::after {
		display: none;
	 }

	.header-main .navbar-expand-lg .navbar-collapse{text-align: center !important;justify-content: center;}
	.nav-btn-col{color: #000 !important;}
	.nav-item {
		text-align: center;
	}
	.main-navigation ul li:last-child .nav-link{margin: 10px auto;color: #000 !important; }


	.about-slider-sec .owl-carousel .item .owl-item-inner h2, .about-slider-sec .owl-carousel .item .owl-item-inner h6{ margin-left: 55%; }

	.latest-post-sec .post-right-side p{ font-size: 10px; }
	.latest-post-sec .post-right-side {padding: 0px 5px; }
	.footer-main .col-md-12 p{ font-size: 3.1vw; }

	.login-company-container img {
		max-width: 75px;
	}

	.login-company-container i {
		width: 75px;
		height: 75px;
		font-size: 75px;
	}

	.login-company-container a {
		font-size: 24px;
	}

	.login-company-container .row {
		padding: 10px 0px;
	}

	#header-image-mobile {
		display: inline-block;
		width: 100px;
	}

	#header-image {
		display: none;
	}
	
	.get-started-btn { width: 70%; font-size: 20px; }
}

@media ( max-width: 950px ){
	.blog-inner { 
		flex-direction: column;
	}

	.blog-item {
		margin-top: 20px;
		width: 100%;
		max-width: 100%;
	}

	.blog-item img {
		height: auto;
		width: 100%;
	}

	.blog-item h5 {
		width: 100%;
	}
}
